import Cookies from 'js-cookie'

/**
 * 定义TOKEN的 key
 * @type {string}
 */
const TOKEN_KEY = 'token';
/**
 * 定义用户Id key
 * @type {string}
 */
const USER_ID_KEY = 'userId';
/**
 * 定义用户名称 key
 * @type {string}
 */
const USER_NAME_KEY = 'userName';



/**************************************************** set ************************************************************/
/**
 * 设置token到cookies里面
 * @param params
 * @returns {*}
 */
export function setToken(params) {
    return Cookies.set(TOKEN_KEY, params)
}

/**
 * 设置用户id到cookies里面
 * @param params
 * @returns {*}
 */
export function setUserId(params) {
    return Cookies.set(USER_ID_KEY, params)
}

/**
 * 设置员工名称到cookies里面
 * @param params
 * @returns {*}
 */
export function setUserName(params) {
    return Cookies.set(USER_NAME_KEY, params)
}


/**************************************************** get ************************************************************/

/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
    return Cookies.get(TOKEN_KEY)
}

/**
 * 获取用户id
 * @returns {*}
 */
export function getUserId() {
    return Cookies.get(USER_ID_KEY)
}

/**
 * 获取用户名称
 * @returns {*}
 */
export function getUserName() {
    return Cookies.get(USER_NAME_KEY)
}

/**************************************************** remove ************************************************************/
/**
 * 移除用户id
 * @returns {*}
 */
export function removeUserId() {
    return Cookies.remove(USER_ID_KEY)
}

/**
 * 移除token
 * @returns {*}
 */
export function removeToken() {
    return Cookies.remove(TOKEN_KEY)
}

/**
 * 移除用户名称
 * @returns {*}
 */
export function removeUserName() {
    return Cookies.remove(USER_NAME_KEY)
}



/**
 * 移除所有
 * @returns {*}
 */
export function remove() {
    Cookies.remove(USER_ID_KEY)
    Cookies.remove(USER_NAME_KEY)
    Cookies.remove(TOKEN_KEY)
}
