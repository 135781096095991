import Vue from 'vue';
import VueRouter from 'vue-router';


const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name:'home',
        component: ()=>import('../views/mobile/home/home'),
        meta: {
            title: '捡穗账 记账-首页',
        }
    },
    {
        path: '/login',
        name:'login',
        component: ()=>import('../views/mobile/login/login'),
        meta: {
            title: '捡穗账 记账-登录',
        }
    },
    {
        path: '/register',
        name:'register',
        component: ()=>import('../views/mobile/login/register'),
        meta: {
            title: '捡穗账 记账-注册',
        }
    },
    {
        path: '/cost',
        name:'cost',
        component: ()=>import('../views/mobile/cost/index'),
        meta: {
            title: '费用详情',

        }
    },
    {
        path: '/updateCost',
        name:'updateCost',
        component: ()=>import('../views/mobile/cost/update'),
        meta: {
            title: '编辑',
        }
    },
    {
        path: '/addCost',
        name:'addCost',
        component: ()=>import('../views/mobile/cost/add'),
        meta: {
            title: '记一笔',
        }
    },
    {
        path: '/taxPayment',
        name:'taxPayment',
        component: ()=>import('../views/mobile/taxPayment/index'),
        meta: {
            title: '纳税',
        }
    },
    {
        path: '/declaration',
        name:'declaration',
        component: ()=>import('../views/mobile/taxPayment/declaration'),
        meta: {
            title: '申报',
        }
    },
    {
        path: '/declarationDetails',
        name:'declarationDetails',
        component: ()=>import('../views/mobile/taxPayment/declarationDetails'),
        meta: {
            title: '申报详情',
        }
    }
    ,
    {
        path: '/myData',
        name:'myData',
        component: ()=>import('../views/mobile/my/index'),
        meta: {
            title: '我的',
        }
    }
    ,
    {
        path: '/login-p',
        name:'loginP',
        component: ()=>import('../views/pc/login/login'),
        meta: {
            title: '捡穗账 记账-登录',
        }
    }
    ,
    {
        path: '/register-p',
        name:'registerP',
        component: ()=>import('../views/pc/login/register'),
        meta: {
            title: '捡穗账 记账-注册',
        }
    }
    ,
    {
        path: '/home-p',
        name:'homeP',
        component: ()=>import('../views/pc/home/home'),
        meta: {
            title: '捡穗账',
        },
        children:[
            {
                path: '/',
                redirect: '/cost-list'
            },
            {
                path:"/cost-list",
                name:'costList',
                component: ()=>import('../views/pc/cost/index'),
                meta: {
                    title: '捡穗账',
                },
            },
            {
                path:"/tax-payment-list",
                name:'taxPaymentList',
                component: ()=>import('../views/pc/taxPayment/index'),
                meta: {
                    title: '捡穗账',
                },
            }
        ]
    }
    ,
    {
        path: '/demo',
        name:'demo',
        component: ()=>import('../views/demo'),
        meta: {
            title: '我的',
        }
    }
]
Vue.use(VueRouter)


const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode:'history',
    linkActiveClass: 'active'
})



//新版本记录路由导航控制台会报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
