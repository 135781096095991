import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);

const actions = {}
// var partners = null;
const mutations = {
    routerPath: (state, path) => {
        state.routerPath = path;
    },
    handlePartner: (state, partner) => {
        state.partner = partner;
        state.partnerType = 0;
        // partners = partner;
        // 把登录的用户的名保存到localStorage中，防止页面刷新，导致vuex重新启动，用户名就成为初始值（初始值为空）的情况
        localStorage.setItem('partner', JSON.stringify(partner))
        localStorage.setItem('partnerType', "0");
    },
    handlePartnerToken: (state, partner) => {
        state.token = partner.token;
        localStorage.setItem('token', JSON.stringify(partner.token))

    },
    handlePartnerCorp: (state, partnerCorp) => {
        state.partnerCorp = partnerCorp;
        state.partnerType = 1;
        // partners = partner;
        // 把登录的用户的名保存到localStorage中，防止页面刷新，导致vuex重新启动，用户名就成为初始值（初始值为空）的情况
        localStorage.setItem('partnerCorp', JSON.stringify(partnerCorp))
        localStorage.setItem('partnerType', "1")
    },
    handlePartnerCorpToken: (state, partnerCorp) => {
        state.token = partnerCorp.token;
        localStorage.setItem('token', JSON.stringify(partnerCorp.token))

    }
}
const state = {
    partner: JSON.parse(localStorage.getItem("partner")),
    token: JSON.parse(localStorage.getItem("token")),
    partnerCorp: JSON.parse(localStorage.getItem("partnerCorp")),
    partnerType: localStorage.getItem("partnerType"),
    routerPath:'',
}
// getters 只会依赖 state 中的成员去更新
const getters = {
    partner: (state) => state.partner,
    token: (state) => state.token,
    partnerCorp: (state) => state.partnerCorp,
    partnerType: (state) => state.partnerType,
    routerPath:(state) => state.routerPath

}

const store = new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})


export default store;