import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import router from './router/router' //导入路由对象
import store from "@/store";
import 'vant/lib/index.css';
import '@vant/touch-emulator';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//引入阿里图标
import "../src/assets/icon/iconfont.css";

Vue.use(ElementUI);
Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')
