<template>
  <div id="app">
    <router-view :key="key"></router-view>
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>
import {getToken, getUserId} from "@/utils/authUtils";
import {isMobile} from "@/utils/terminalUtils";

export default {
  name: 'App',
  computed: {
    key() {
      return this.$route.fullPath
    }
  },
  created() {
    if (isMobile()) {
      let token = getToken();
      let userId = getUserId();
      if (!token && !userId) {
        this.$router.replace('/login');
      }

    } else {
      let token = getToken();
      let userId = getUserId();
      if (!token && !userId) {
        this.$router.replace('/login-p');
      }
    }
  },
  methods: {

  },

}
</script>

<style>
</style>
